<template>
  <card-with-left-title
    class="model-list"
    :title="$t('miniApp.form.algorithmModel')"
  >
    <div
      v-for="(model, index) in originalModelList"
      :key="model.modelId"
      class="model-list__row"
    >
      <div class="model-list__partial-left">
        <svg-icon icon-class="algo-model" />
        <span>{{ model.modelName }}</span>
      </div>
      <div class="model-list__partial-right">
        <svg-icon icon-class="arrow-right2" />
        <default-select
          v-model="selectedModelIdList[index]"
          :option-list="modelOptionList[index]"
          :is-disabled="disabled || isFirstLoading"
          :placeholder="$t('miniApp.form.selectModel')"
          :enable-lazy-load="true"
          @intersect="loadModelList"
        />
        <template v-if="hasRepeatSelectedModel(selectedModelIdList[index])">
          <svg-icon
            class="error"
            icon-class="error"
          />
          <span class="error">{{ $t('miniApp.form.modelRepeatError') }}</span>
        </template>
        <template v-else>
          <svg-icon
            class="warning"
            :class="{ invisible: selectedModelIdList[index] }"
            icon-class="warning2"
          />
        </template>
      </div>
    </div>
  </card-with-left-title>
</template>

<script>
import { uniqBy } from 'lodash'
import { defineComponent, ref, computed, watch, onMounted } from '@vue/composition-api'
import { getModelList, getModelInfo } from '@/API/Model'
import { getRecommendModelList } from '@/API/MiniApp'
import DefaultSelect from '@/components/select/DefaultSelect.vue'
import CardWithLeftTitle from './CardWithLeftTitle.vue'

export default defineComponent({
  name: 'ModelList',
  components: {
    CardWithLeftTitle,
    DefaultSelect
  },
  props: {
    groupId: {
      type: Number,
      required: true
    },
    /**
     * @typedef {import('@/API/types/MiniApp').AppDataSettings['dataSettings']['modelList']} OriginalModelList
     */
    originalModelList: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  /**
   * @param {
      {
        groupId: number;
        originalModelList: OriginalModelList;
        disabled: boolean;
      }
   * } props
   */
  setup (props, { emit }) {
    const isFirstLoading = ref(false)
    const selectedModelIdList = ref(
      props.originalModelList.map(() => null)
    )

    const isFinished = computed(() => selectedModelIdList.value.every(Boolean))
    watch(isFinished, (value) => {
      value ? emit('finish') : emit('unfinish')
    }, {
      immediate: true
    })

    let currentPage = 0
    let hasNextPage = true
    const modelList = ref([])
    async function loadModelList () {
      if (!hasNextPage) return
      const { models: _models, pagination: { totalPages } } = await getModelList(props.groupId, currentPage)
      const models = await Promise.all(_models.map(({ id }) => getModelInfo(id)))
      modelList.value.push(...models)
      currentPage++
      hasNextPage = currentPage < totalPages

      if (
        props.mode === 'edit' &&
        selectedModelIdList.value.some((id) => !(id == null || modelList.value.includes(id)))
      ) await loadModelList()
    }

    /**
     * @typedef {import('@/API/types/MiniApp').GetRecommendModelListResult['modelList']} RecommendModelList
     * @type {import('@vue/composition-api').Ref<RecommendModelList[]>}
     */
    const recommendModelList = ref([])
    async function loadRecommendModelList () {
      recommendModelList.value = await Promise.all(
        props.originalModelList.map(async (model) => {
          const { modelList } = await getRecommendModelList({
            filterGroupId: props.groupId,
            model
          })
          return modelList
        })
      ).catch(() => [])

      selectedModelIdList.value = recommendModelList.value.map((item) => {
        return item[0]?.modelId ?? null
      })
    }

    const modelOptionList = computed(() => {
      function getIoStatsTypeListString (list) {
        return list.map(({ statsType }) => statsType).join()
      }
      return props.originalModelList
        .map(({ modelIoArgsDo }, index) => {
          const recommendOptions = (recommendModelList.value[index] ?? [])
            .map(({ modelId, modelName }) => ({
              name: modelName,
              value: modelId
            }))
          const normalOptions = modelList.value
            .filter(({ ioArgs }) => {
              return getIoStatsTypeListString(ioArgs.input) === getIoStatsTypeListString(modelIoArgsDo.input) &&
              getIoStatsTypeListString(ioArgs.output) === getIoStatsTypeListString(modelIoArgsDo.output)
            })
            .map(({ id, name }) => ({ name, value: id }))
          return uniqBy([...recommendOptions, ...normalOptions], 'value')
            .map(({ name, value }) => ({ name: `${name} (ID: ${value})`, value }))
        })
    })

    function hasRepeatSelectedModel (id) {
      if (id === null) return false
      return selectedModelIdList.value
        .filter((selectedId) => selectedId === id)
        .length > 1
    }

    onMounted(async () => {
      isFirstLoading.value = true
      await loadModelList()
      await loadRecommendModelList()
      isFirstLoading.value = false
    })
    watch(() => props.groupId, async () => {
      selectedModelIdList.value = props.originalModelList.map(() => null)
      currentPage = 0
      hasNextPage = true
      modelList.value = []
      recommendModelList.value = []
      isFirstLoading.value = true
      await loadModelList()
      await loadRecommendModelList()
      isFirstLoading.value = false
    })

    function getUpdateData () {
      if (!isFinished.value) return null
      return props.originalModelList
        .map(({ modelId }, index) => ({
          originModelId: modelId,
          replaceModelId: selectedModelIdList.value[index]
        }))
    }

    return {
      isFirstLoading,
      selectedModelIdList,
      modelList,
      modelOptionList,
      hasRepeatSelectedModel,
      loadModelList,
      getUpdateData
    }
  }
})
</script>

<style lang="scss" scoped>
.model-list {
  &__row {
    align-items: center;
    display: flex;
    padding: 0.5rem 1rem;
  }

  &__row:not(:last-of-type) {
    border-bottom: 1px solid #485454;
  }

  &__partial-left {
    align-items: center;
    display: flex;

    .svg-icon {
      margin-right: 0.5rem;
    }
  }

  &__partial-right {
    align-items: center;
    display: flex;
    margin-left: auto;
    width: 55%;

    .svg-icon {
      height: 20px;
      margin-right: 1rem;
      width: 20px;
    }

    ::v-deep .el-input.is-disabled {
      opacity: 0.5;
    }
  }
}

.invisible {
  visibility: hidden;
}

.warning {
  color: #ffdf6f;
}

.error {
  color: #ff5c46;
}
</style>
