<template>
  <div class="data-frame-block">
    <step-card-block
      type="bright"
      class="data-frame-block__data-source"
    >
      <div class="data-frame-block__partial-left">
        <svg-icon icon-class="table" />
        <span>
          {{ `${originalDataFrame.dataSourceName} / ${originalDataFrame.dataFramePrimaryAlias}` }}
        </span>
      </div>
      <div class="data-frame-block__partial-right">
        <svg-icon icon-class="arrow-right2" />
        <div class="select-container">
          <svg-icon
            v-show="isLoadingDataSourceOptions"
            icon-class="spinner"
          />
          <default-select
            v-model="selectedDataSourceId"
            :option-list="dataSourceOptionList"
            :is-disabled="disabled || isLoadingDataSourceOptions"
            :placeholder="$t('miniApp.form.selectDataSource')"
          />
        </div>
        <div>/</div>
        <div class="select-container">
          <svg-icon
            v-show="isLoadingDataSourceOptions"
            icon-class="spinner"
          />
          <default-select
            v-model="selectedDataFrameId"
            :option-list="dataFrameOptionList"
            :is-disabled="disabled || isLoadingDataFrameOptions || unfinished.dataSource"
            :placeholder="$t('miniApp.form.selectDataFrame')"
          />
        </div>
        <el-tooltip
          visible-arrow
          placement="bottom"
          :content="$t('miniApp.form.recommendExplain')"
        >
          <svg-icon icon-class="information" />
        </el-tooltip>
        <svg-icon
          class="warning"
          :class="{ invisible: !(unfinished.dataSource || unfinished.dataFrame) }"
          icon-class="warning2"
        />
      </div>
    </step-card-block>
    <div
      v-for="(dataColumn, index) in originalDataFrame.dataColumnList"
      :key="dataColumn.dataColumnId"
      class="data-frame-block__data-column"
    >
      <div class="data-frame-block__partial-left">
        <svg-icon :icon-class="getColumnIconMap(dataColumn.statsType)" />
        <span>{{ dataColumn.primaryAlias }}{{ checkOrdinalText(dataColumn.statsType, dataColumn.isOrdinal) }}</span>
      </div>
      <div class="data-frame-block__partial-right">
        <svg-icon icon-class="arrow-right2" />

        <div class="select-container">
          <svg-icon
            v-show="isLoadingDataSourceOptions"
            icon-class="spinner"
          />
          <default-select
            v-model="selectedDataColumnIdList[index]"
            :option-list="dataColumnOptionList[index]"
            :is-disabled="disabled || isLoadingDataColumnOptions || unfinished.dataFrame"
            :placeholder="$t('miniApp.form.selectDataColumn')"
            filterable
          />
        </div>
        <template v-if="hasRepeatSelectedDataColumn(selectedDataColumnIdList[index])">
          <svg-icon
            class="error"
            icon-class="error"
          />
          <span class="error">{{ $t('miniApp.form.columnRepeatError') }}</span>
        </template>
        <template v-else>
          <svg-icon
            class="warning"
            :class="{ invisible: !unfinished.dataColumns[index] }"
            icon-class="warning2"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed, watch, onMounted } from '@vue/composition-api'
import { getDataFrameColumnInfoById } from '@/API/DataSource'
import {
  getRecommendDataSource,
  getRecommendDataFrameList,
  getRecommendDataColumnList
} from '@/API/MiniApp'
import { useI18n } from '@/utils/composable/i18n'
import { uniqBy } from 'lodash'
import StepCardBlock from '@/modules/shared/flow/components/StepCardBlock.vue'
import DefaultSelect from '@/components/select/DefaultSelect.vue'

export default defineComponent({
  name: 'DataFrameBlock',
  components: {
    StepCardBlock,
    DefaultSelect
  },
  props: {
    groupId: {
      type: Number,
      required: true
    },
    /**
     * @typedef {import('@/API/types/MiniApp').DataSettingDataFrame} OriginalDataFrameData
     */
    originalDataFrame: {
      type: Object,
      required: true
    },
    /**
     * @typedef {
        {
          id: number;
          name: string;
          dataFrames: {
            id: number,
            primaryAlias: string;
          }[];
        }[]
     * } DataSourceList
     */
    dataSourceList: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  /**
   * @param {
      {
        groupId: number;
        dataSourceList: DataSourceList;
        originalDataFrame: OriginalDataFrameData;
        disabled: boolean
      }
   * } props
   */
  setup (props, { emit }) {
    function getColumnIconMap (columnStatType) {
      return {
        CATEGORY: 'character-a',
        NUMERIC: 'numeric',
        BOOLEAN: 'checked',
        DATETIME: 'calendar'
      }[columnStatType] ?? 'check-circle'
    }

    const recommendation = ref({
      /** @type {import('@/API/types/MiniApp').GetRecommendDataSourceResult['dataSource'] | null} */
      dataSource: null,
      /** @type {import('@/API/types/MiniApp').GetRecommendDataFrameListResult['dataFrameList']} */
      dataFrameList: [],
      /** @type {import('@/API/types/MiniApp').GetRecommendDataColumnListResult['dataColumns']} */
      dataColumnList: []
    })

    const isLoadingDataSourceOptions = ref(false)

    /**
     * @param {number} groupId
     */
    async function loadRecommendDataSource (groupId) {
      isLoadingDataSourceOptions.value = true
      const { dataSource } = await getRecommendDataSource({
        filterGroupId: groupId,
        dataFrame: props.originalDataFrame
      }).catch(() => null)
      recommendation.value.dataSource = dataSource
      selectedDataSourceId.value = dataSource?.dataSourceId ?? null
      isLoadingDataSourceOptions.value = false
    }

    const isLoadingDataFrameOptions = ref(false)

    /**
     * @param {number} dataSourceId
     */
    async function loadRecommendDataFrameList (dataSourceId) {
      isLoadingDataFrameOptions.value = true
      const { dataFrameList } = await getRecommendDataFrameList({
        filterDataSourceId: dataSourceId,
        dataFrame: props.originalDataFrame
      }).catch(() => [])
      recommendation.value.dataFrameList = dataFrameList
      selectedDataFrameId.value = dataFrameList[0]?.dataFrameId ?? null
      isLoadingDataFrameOptions.value = false
    }

    const isLoadingDataColumnOptions = ref(false)

    /**
     * @param {number} dataFrameId
     */
    async function loadRecommendDataColumnList (dataFrameId) {
      isLoadingDataColumnOptions.value = true
      const { dataColumns } = await getRecommendDataColumnList({
        filterDataFrameId: dataFrameId,
        dataFrame: props.originalDataFrame
      }).catch(() => [])
      recommendation.value.dataColumnList = dataColumns
      selectedDataColumnIdList.value = dataColumns.map(({ dataColumnId }) => dataColumnId)
      isLoadingDataColumnOptions.value = false
    }

    const selectedDataSourceId = ref(null)
    watch(() => props.groupId, (value) => {
      selectedDataSourceId.value = null
      loadRecommendDataSource(value)
    })

    const selectedDataFrameId = ref(null)
    watch(selectedDataSourceId, (value) => {
      selectedDataFrameId.value = null
      if (value == null) return
      loadRecommendDataFrameList(value)
    })

    const dataColumnList = ref([])
    const selectedDataColumnIdList = ref(
      props.originalDataFrame.dataColumnList.map((dataColumn) => null)
    )
    watch(selectedDataFrameId, async (value) => {
      selectedDataColumnIdList.value = props.originalDataFrame.dataColumnList.map(() => null)
      if (value == null) {
        dataColumnList.value = []
        return
      }
      loadRecommendDataColumnList(value)
      dataColumnList.value = (await getDataFrameColumnInfoById(value))
        .map(({ id, name, statsType, isOrdinal }) => ({ id, name, statsType, isOrdinal }))
    })

    onMounted(async () => {
      loadRecommendDataSource(props.groupId)
    })

    const unfinished = computed(() => ({
      dataSource: selectedDataSourceId.value == null,
      dataFrame: selectedDataFrameId.value == null,
      dataColumns: selectedDataColumnIdList.value.map((id) => id == null)
    }))
    const isFinished = computed(() => {
      return !(
        unfinished.value.dataSource ||
        unfinished.value.dataFrame ||
        unfinished.value.dataColumns.some(Boolean)
      )
    })
    watch(isFinished, (value) => {
      value ? emit('finish') : emit('unfinish')
    }, {
      immediate: true
    })

    function hasRepeatSelectedDataColumn (id) {
      if (id === null) return false
      return selectedDataColumnIdList.value
        .filter((selectedId) => selectedId === id)
        .length > 1
    }

    const dataSourceOptionList = computed(() => {
      const normalOptions = props.dataSourceList.map(({ id, name }) => ({ name, value: id }))
      const recommendOptions = recommendation.value.dataSource == null
        ? []
        : [{
          name: recommendation.value.dataSource.dataSourceName,
          value: recommendation.value.dataSource.dataSourceId
        }]
      return uniqBy([
        ...recommendOptions,
        ...normalOptions
      ], 'value')
    })
    const { t } = useI18n()
    const dataFrameOptionList = computed(() => {
      const originalText = t('miniApp.form.original')
      const recommendOptions = recommendation.value.dataFrameList.map(({
        primaryAlias,
        dataFrameId,
        isOrigin,
        fitPercent
      }) => ({
        name: `${primaryAlias} (${isOrigin ? originalText : `${fitPercent}%`})`,
        value: dataFrameId
      }))

      return recommendOptions
    })
    const dataColumnOptionList = computed(() => {
      const map = Object.fromEntries(
        recommendation.value.dataColumnList.map(({
          originDataColumnId,
          dataColumnId,
          primaryAlias
        }) => [
          originDataColumnId,
          {
            dataColumnId,
            primaryAlias
          }
        ])
      )

      return props.originalDataFrame.dataColumnList
        .map(({ dataColumnId, statsType, isOrdinal }, index) => {
          const normalOptions = dataColumnList.value
            .filter(({ statsType: _statsType, isOrdinal: _isOrdinal }) => {
              if (statsType === 'NUMERIC') {
                return (statsType === _statsType) && (isOrdinal === _isOrdinal)
              }
              return statsType === _statsType
            })
            .map(({ id, name }) => ({ name, value: id }))

          const recommend = map[dataColumnId]
          const recommendOptions = recommend == null
            ? []
            : [{
              name: `${recommend.primaryAlias} (${t('miniApp.form.recommended')})`,
              value: recommend.dataColumnId
            }]
          return uniqBy([
            ...recommendOptions,
            ...normalOptions
          ], 'value')
        })
    })

    function getUpdateData () {
      if (!isFinished.value) return null

      return {
        dataColumns: props.originalDataFrame.dataColumnList
          .map(({ dataColumnId }, index) => ({
            originDataColumnId: dataColumnId,
            replaceDataColumnId: selectedDataColumnIdList.value[index]
          })),
        originDataFrameId: props.originalDataFrame.dataFrameId,
        replaceDataFrameId: selectedDataFrameId.value,
        originDataSourceId: props.originalDataFrame.dataSourceId,
        replaceDataSourceId: selectedDataSourceId.value
      }
    }

    function checkOrdinalText (type, isOrdinal) {
      if (type === 'NUMERIC') {
        return ` (${isOrdinal ? this.$t('common.isOrdinal') : this.$t('common.isNotOrdinal')})`
      }
      return ''
    }

    return {
      getColumnIconMap,
      recommendation,
      selectedDataSourceId,
      selectedDataFrameId,
      selectedDataColumnIdList,
      unfinished,
      hasRepeatSelectedDataColumn,
      dataColumnList,
      dataSourceOptionList,
      dataFrameOptionList,
      dataColumnOptionList,
      isLoadingDataSourceOptions,
      isLoadingDataFrameOptions,
      isLoadingDataColumnOptions,
      getUpdateData,
      checkOrdinalText
    }
  }
})
</script>

<style lang="scss" scoped>

.data-frame-block {
  .step-card-block:not(:last-child) {
    margin: 0;
  }

  &__data-source,
  &__data-column {
    align-items: center;
    display: flex;
    padding: 0.5rem 1rem;
  }

  &__data-column {
    border-bottom: 1px solid #485454;
  }

  &__partial-left {
    align-items: center;
    display: flex;
    max-width: 40%;

    .svg-icon {
      margin-right: 0.5rem;
    }
  }

  &__partial-right {
    align-items: center;
    display: flex;
    margin-left: auto;
    width: 55%;

    .svg-icon {
      flex-shrink: 0;
      height: 20px;
      margin-right: 0.5rem;
      width: 20px;
    }

    ::v-deep .el-input.is-disabled {
      opacity: 0.5;
    }
  }
}

.select-container {
  align-items: center;
  display: flex;

  .svg-icon {
    margin-left: 0.5rem;
    margin-right: -0.5rem;
  }
}

.invisible {
  visibility: hidden;
}

.warning {
  color: #ffdf6f;
}

.error {
  color: #ff5c46;
}
</style>
