<template>
  <div
    class="step-card-block"
    :class="[`step-card-block--${type}`]"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'StepCardBlock',
  props: {
    type: {
      type: String,
      default: 'default',
      validator: (value) => ['default', 'dark', 'bright'].includes(value)
    }
  }
})
</script>

<style lang="scss" scoped>
.step-card-block {
  border-radius: 5px;
  padding: 24px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  &--default {
    background-color: rgba(50, 58, 58, 0.95);
  }

  &--bright {
    background-color: #485454;
  }

  &--dark {
    background-color: #1c2121;
  }
}
</style>
