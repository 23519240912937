<template>
  <card-with-left-title :title="$t('editing.sourceOfData')">
    <data-frame-block
      v-for="(originalDataFrame, i) in originalDataFrameList"
      :key="i"
      ref="dataFrameBlock"
      :group-id="groupId"
      :data-source-list="dataSourceList"
      :original-data-frame="originalDataFrame"
      :disabled="disabled || isLoading"
      @finish="statusOfFinishList.splice(i, 1, true)"
      @unfinish="statusOfFinishList.splice(i, 1, false)"
    />
  </card-with-left-title>
</template>

<script>
import { defineComponent, ref, computed, onMounted, watch } from '@vue/composition-api'
import { getDataSourceList } from '@/API/DataSource'

import CardWithLeftTitle from './CardWithLeftTitle.vue'
import DataFrameBlock from './DataFrameBlock.vue'

export default defineComponent({
  name: 'DataFrameList',
  components: {
    CardWithLeftTitle,
    DataFrameBlock
  },
  props: {
    groupId: {
      type: Number,
      required: true
    },
    /**
     * @typedef {import('@/API/types/MiniApp').AppDataSettings['dataSettings']['dataFrameList']} OriginalDataFrameList
     */
    originalDataFrameList: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  /**
   * @param {
      {
        groupId: number;
        originalDataFrameList: OriginalDataFrameList;
        disabled: boolean;
      }
   * } props
   */
  setup (props, { refs, emit }) {
    const isLoading = ref(false)
    const _dataSourceList = ref([])
    async function loadDataSourceList () {
      _dataSourceList.value = await getDataSourceList(props.groupId)
    }
    const dataSourceList = computed(() => {
      return _dataSourceList.value.map(({ id, name, dataFrames }) => ({
        id,
        name,
        dataFrames: dataFrames.map(({ id, primaryAlias }) => ({ id, primaryAlias }))
      }))
    })

    onMounted(async () => {
      isLoading.value = true
      await loadDataSourceList()
      isLoading.value = false
    })

    watch(() => props.groupId, async (newGroupId) => {
      isLoading.value = true
      await loadDataSourceList()
      isLoading.value = false
    })

    const statusOfFinishList = ref(props.originalDataFrameList.map(() => false))
    const isFinished = computed(() => {
      return statusOfFinishList.value.every(Boolean)
    })
    watch(isFinished, (value) => {
      value ? emit('finish') : emit('unfinish')
    }, {
      immediate: true
    })

    function getUpdateData () {
      const data = props.originalDataFrameList
        .map((_, index) => {
          return refs.dataFrameBlock[index].getUpdateData()
        })

      if (!data.every(Boolean)) return null

      return data
    }

    return {
      isLoading,
      isFinished,
      getUpdateData,
      dataSourceList,
      statusOfFinishList
    }
  }
})
</script>
