<template>
  <step-card-block
    v-bind="$attrs"
    v-on="$listeners"
  >
    <div class="card-title">
      {{ title }}
    </div>
    <slot />
  </step-card-block>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import StepCardBlock from '@/modules/shared/flow/components/StepCardBlock.vue'

export default defineComponent({
  name: 'CardWithLeftTitle',
  components: {
    StepCardBlock
  },
  props: {
    title: {
      type: String,
      required: true
    }
  }
})
</script>

<style lang="scss" scoped>
.card-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 1rem;
}
</style>
